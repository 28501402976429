module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PVS8WGR","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Art Social","short_name":"Art Social","start_url":"/","background_color":"#1e7a99","theme_color":"#1e7a99","display":"minimal-ui","icon":"src/images/favicon.svg","icon_options":{"purpose":"any maskable"},"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e1d1dce41ab4186a875d09d3324a7ec4"},
    },{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"artsocial"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
