import * as React from 'react'
import { Link } from 'gatsby'
import { PrismicProvider } from '@prismicio/react'
import { PrismicPreviewProvider } from 'gatsby-plugin-prismic-previews'

// Utils
import { repositoryConfigs } from './src/utils/prismicPreviews'
import { linkResolver } from './src/utils/linkResolver'

import './src/styles/default.css'
import '@fontsource/manrope/latin.css'
import '@fontsource/roboto/latin.css'

export const wrapRootElement = ({ element }) => (
  <PrismicProvider
    linkResolver={linkResolver}
    externalLinkComponent={({ href, className, ...props }) => {
      if (href.includes(process.env.GATSBY_COMPANY_URL)) {
        return (
          <a data-location='internal' href={href} target='_self' className={className}>
            {props.children}
          </a>
        )
      }
      return (
        <a data-location='external' href={href} className={className} {...props}>
          {props.children}
        </a>
      )
    }}
    internalLinkComponent={({ href, ...props }) => {
      if (href === 'index' || href === '/') {
        return (
          <a data-location='auction' href={href} {...props}>
            {props.children}
          </a>
        )
      }
      return <Link to={href} {...props} />
    }}>
    <PrismicPreviewProvider repositoryConfigs={repositoryConfigs}>{element}</PrismicPreviewProvider>
  </PrismicProvider>
)
